import Vue from 'vue'
import DonationModalView from './views/DonationModalView.vue'
import donationApi from '@dharmachakra/tbco-donation-modal'

Vue.config.productionTip = false

const donateTriggers = document.querySelectorAll('.donation-trigger')

for (const donateTrigger of donateTriggers) {
  donateTrigger.addEventListener('click', () => {
    donationApi.setDonationPanelOpen(true)
  })
}

new Vue({
  render: h => h(DonationModalView)
}).$mount('.donation-mount')
