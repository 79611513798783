<template>
  <div>
    <DonationModal :show="open" :purposes="purposes" @close="closeDonationPanel"
    />
  </div>
</template>

<script>
import donationApi, { store } from '@dharmachakra/tbco-donation-modal'
import DonationModal from '@dharmachakra/tbco-donation-modal/src/components/DonationModal'

export default {
  components: {
    DonationModal
  },
  mounted() {
    document.__TBCO__.donationApi = donationApi
    document.__TBCO__.store = store

    donationApi.fetchDonationPurposes()
  },
  computed: {
    open() {
      return store.showDonationModal
    },
    purposes() {
      return store.donationPurposes
    }
  },
  methods: {
    closeDonationPanel() {
      donationApi.setDonationPanelOpen(false)
      document.__TBCO__.siteHeaderMenuApi.setToggleButtonState('donate', false)
    },
    openDonationPanel() {
      donationApi.setDonationPanelOpen(true)
    }
  }
}
</script>
